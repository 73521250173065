.Footer_Container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  margin: 0;
  box-sizing: border-box;
}

.Footer_Wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  background-color: rgb(35, 39, 48, 0.5);
  padding: 0px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.3),
    inset 0px 1px 2px 0px rgba(255, 255, 255, 0.3);
}
