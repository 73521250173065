.HomepageDisplay_Container {
  width: 100%;
  height: fit-content;
  margin: 0;
  box-sizing: border-box;
  padding-top: 1rem;
}

.HomepageDisplay_Wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.HomepageDisplayCards_Container {
    width: 100%;
    /*height: fit-content;*/
    height: 50vh;
    margin: 0;
    padding-top: 8px;
    padding-right: 3rem;
    padding-left: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 1rem;
}

/*.HomepageDisplayCards_Container .GameCard_Container {
  max-width: 300px;
}*/

@media only screen and (max-width: 1240px) {
  .HomepageDisplay_Container {
    display: none;
  }
}
