.MainBanner_Container {
  width: 100%;
  height: fit-content;
  margin: 0;
  box-sizing: border-box;
  /* padding-bottom: 1rem; */
  display: flex;
  align-items: center;
  /*flex-grow: 2;*/
}

.MainBanner_Wrapper {
  width: 100%;
  height: fit-content;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.profile_Image {
    width: 80%;
    max-width: 150px;
    aspect-ratio: 1 / 1;
    border: 2px solid #f5ac10;
    box-shadow: 0px 17px 15px 11px rgba(0,0,0,0.71);
    box-sizing: border-box;
}

.Text_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    min-width: 500px;
}

.MainTitle {
    border-right: .15em solid orange;
    animation: blink-caret 1.0s step-end infinite;
    margin-left: 8px;
    font-size: 3.0rem;
    white-space: nowrap;
    display: inline;
}

    .Text_Container h3 {
        margin-left: 8px;
        white-space: nowrap;
    }

.MainTitle div {
    display: inline !important;
    margin-left: 15px;
}

.MainBanner_Socials {
    width: 100%;
    display: flex;
    justify-content: normal;
    /*justify-content: space-evenly;*/
}

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange }
}

/* ----- Movile ----- */
@media only screen and (max-width: 704px) {
    .Text_Container h1 {
        margin-left: 8px;
        font-size: 2.5rem;
        white-space: nowrap;
    }

    .Text_Container {
        min-width: auto;
    }

    .profile_Image {
        /*width: 80%;
        max-width: 120px;
        aspect-ratio: 1 / 1;
        border: 2px solid #f5ac10;
        box-shadow: 0px 0px 5px 0px #000;
        box-sizing: border-box;*/
    }
}

@media only screen and (max-width: 1240px) {
    .profile_Image {
        display: none;
    }

    .MainBanner_Socials {
        justify-content: center;
    }
}

@media only screen and (min-width: 1240px) {
  .MainBanner_Wrapper {
    flex-direction: row;
    justify-content: center;
  }

  .Text_Container {
    align-items: flex-start;
  }

    .Text_Container h1 {
        font-size: 4.0rem;
    }
}

@media only screen and (min-width: 1921px) {
    /*.profile_Image {
    max-width: 500px;
  }*/
    /*.MainTitle_xl {
    font-size: 6rem;
  }

  .Subtitle_xl {
    font-size: 3rem;
  }*/
}


