.DataCard_Container {
  width: 100%;
  height: fit-content;
  margin: 0;
  box-sizing: border-box;
  background-color: #15161b;
  box-shadow: 0px 0px 5px 0px #000;
  border: 1px solid #a3b1be;
}

.DataCard_Wrapper {
  height: fit-content;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.MuiCardContent-root {
    padding-bottom: 16px;
  }
}

.DataCard_Header {
  display: flex;
  gap: 16px;
}

.DataCard_Logo {
  height: 52px;
  width: 52px;
  border: 1px solid #a3b1be;
}

.DataCard_HeaderText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
