.GamesSection_Container {
  width: 100%;
  height: calc(100% - 59px - 30px);
  margin: 0;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

.GamesSection_Wrapper {
  width: 100%;
  height: fit-content;
  margin: 0;
  box-sizing: border-box;
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.Section_Title {
  height: fit-content;
  text-transform: uppercase;
}

.GamesSectionCard_Container {
  width: 100%;
  height: fit-content;
}

.GamesSectionCard_Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /*flex-direction: column;
  align-items: center;*/
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 1rem;
}

@media only screen and (min-width: 768px) {
  .GamesSection_Wrapper {
    padding-top: 3rem;
    gap: 2rem;
  }

  .GamesSectionCard_Wrapper {
/*    flex-direction: row;
    align-items: stretch;*/
    /*flex-wrap: wrap;*/
  }
}

@media only screen and (min-width: 1920px) {
  .GamesSectionCard_Wrapper {
    gap: 2rem;
  }
}
