.SocialsCard_Container {
    width: 100%;
    height: fit-content;
    margin: 0;
    box-sizing: border-box;
    background-color: #232730;
    box-shadow: 0px 17px 15px 11px rgba(0, 0, 0, 0.71);
}

.SocialsCard_Wrapper {
  height: fit-content;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 16px;

  &.MuiCardContent-root {
    padding-bottom: 16px;
  }
}

.Card_Subtitle {
  text-align: center;
}

.Socials_Container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  height: fit-content;
}

.Socials_Logo {
  height: 32px;
  font-size: 27px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SocialsCard_Container a:hover {
  color: #f5ac10;
  text-decoration: underline;
}
