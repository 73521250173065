.IndexMenu_Container {
  width: 100%;
  height: fit-content;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
}

.CategoryMenu_Button {
  text-transform: lowercase;
  color: #a3b1be;
  border-radius:18px;
  border-color: orange;
}

.CategoryMenu_Button > p {
  text-transform: capitalize;
  margin: 0;
}

.ActiveCategory {
  border: 1px solid #f5ac10;
  background-color: #15161b;
  box-shadow: 0px 0px 5px 0px #000;
  color: #f5ac10;
  border-right-color: #f5ac10;
}
