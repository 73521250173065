.hidden {
    display: none;
    transition: all;
}

.CardBodyImage {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100;
}

.GameCard_Container {
    /*  Base  */
    /*width: 20%;*/
    /*max-width: 325px;*/
    aspect-ratio: 3/5;
    border-radius: 18px;
    background-color: transparent;
    /*  Shadow  */
    -webkit-box-shadow: 0px 17px 15px 11px rgba(0,0,0,0.71);
    -moz-box-shadow: 0px 17px 15px 11px rgba(0,0,0,0.71);
    box-shadow: 0px 17px 15px 11px rgba(0,0,0,0.71);
    transition: transform .2s;
}

.GameCard_Container_OnGallery {
    /*max-width: 300px;
    min-width: 100px;*/
    width: 17%;
    max-width: 300px;
}

.GameCard_Container:hover {
    outline: 4px solid #f5ac10;
    transform: scale(1.1);
    z-index: 10;
    cursor: pointer;
}

.Card_Bordy {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.Card_Content {
    position: absolute;
    bottom: 0px;
    height: 100%;
    width: 100%;
    align-content: center;
    z-index: 100;
    /*background-image: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(9,9,121,0) 20%, rgba(0,212,255,0) 100%);*/
}

.Card_Date {
    color: rgb(225, 225, 225);
    position: absolute;
    padding-top: 4px;
    right: 15px;
    bottom: 5px;
}

.fadeIn {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: 0.5;
    }

    to {
        opacity: 1;
    }
}

/* ------ Less Than normal Window ------ */
@media only screen and (max-width: 1240px) {
    .GameCard_Container {
        /*height: 100%;*/
        /*min-width: 320px;*/
    }

    .GameCard_Container_OnGallery {
        /*max-width: 200px;
        min-width: 100px;*/
    }
}

/* ----- Movile ----- */
@media only screen and (max-width: 704px) {
    .GameCard_Container {
/*        max-width: 400px;
        min-width: 250px;*/
    }

    .GameCard_Container_Featured {
        /*height: 300px;*/
        height: 100%;
    }

    .GameCard_Container_OnGallery {
        max-width: 250px;
        min-width: 150px;
    }

    .Card_Date {
        font-size: 2vw;
    }
}
