.AboutPage_Container {
  width: 100%;
  height: calc(100% - 59px - 30px);
  margin: 0;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.AboutPage_Wrapper {
  width: 100%;
  height: fit-content;
  margin: 0;
  box-sizing: border-box;
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  overflow-x: hidden;
}

.AboutPageSections_Wrapper {
  width: 100%;
  max-width: 768px;
  height: fit-content;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
