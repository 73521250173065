.Homepage_Container {
  width: 100%;
  height: calc(100% - 59px - 30px);
  margin: 0;
  box-sizing: border-box;
}

.Homepage_Wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    padding-top: 2rem;
    padding-bottom: 1rem;
/*    padding-left: 1rem;
    padding-right: 1rem;*/
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
}

@media only screen and (min-width: 1240px) {
  /* .Homepage_Wrapper {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  } */
}