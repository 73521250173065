.GamePage_Container {
  width: 100%;
  height: calc(100% - 59px - 30px);
  margin: 0;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.GamePage_Wrapper {
  width: 100%;
  max-width: 768px;
  height: fit-content;
  margin: 0;
  box-sizing: border-box;
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.Page_Title {
    height: fit-content;
    text-transform: uppercase;
    text-align: center;
    font-size: 2.5rem;
}

.Page_Date {
    height: fit-content;
    text-align: center;
    font-size: 1.0rem;
    color: gray;
}

.GamePage_BannerImage {
    /*width: calc(100% + 2rem);*/
    width: 100%;
    border-radius: 18px;
    box-sizing: border-box;
    max-height: 30vh;
    object-fit: cover;
    box-shadow: 0px 17px 15px 11px rgba(0, 0, 0, 0.71);
}


@media only screen and (max-width: 768px) {
/*  .GamePage_BannerImage {
    width: 100%;
    border-radius: 18px;
  }*/

    .Page_Title {
        font-size: 1.5rem;
    }
}