html {
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
ol,
li,
em,
b {
  margin: 0;
}

a {
  color: #fff;
}
